@mixin flexCss {
  display: flex;
  justify-content: space-between;
}
@mixin fontStyle {
  color: #4d4d4d;
  font-family: Montserrat;
  line-height: normal;
  font-style: normal;
}
.createdAssessmentList {
  .filtersSec {
    background-color: #f9f9f9;
    padding: 0px 0px;
    margin-bottom: 20px;
    .statusFilter {
      @include flexCss;
      gap: 50px;
      margin-bottom: 22px;
      .radioCheckLabel {
        @include fontStyle;
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        position: relative;
        top: 3px;
        cursor: pointer;
      }
      .responseSecFilter {
        gap: 20px;
        margin-top: 30px;
        flex-wrap: wrap;
        .rtlGap {
          html[dir="rtl"] & {
            gap: 10px;
          }
        }
      }
    }
    .assessmentListSearchSec {
      z-index: 3 !important;
    }
    .filterClicked {
      z-index: 1 !important;
      .dropdowns-tickets {
        display: flex;
        gap: 21px;
        width: 30%;
      }
    }
    .assessmentListSearchSec {
      display: flex;
      align-items: center;
      gap: 20px;
      position: relative;
      z-index: 1;
      .templateSearchField {
        width: 100%;
      }
      .templateSearchField .templateSearchIconSec input.form-control {
        box-shadow: 0px 5px 25px 0px #c0c0c033;
        border: 2px solid #e9e9e9;
      }
    }
    .filterClicked {
      @include flexCss;
      padding: 30px 42px;
      gap: 50px;
      position: relative;
      top: -25px;
      z-index: 0;
      padding-top: 50px;
      width: calc(100% + 80px);
      left: -40px;
      .typeSelectSec {
        display: flex;
        gap: 21px;
        .skillAssessList {
          .customSelectType .selected-option {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 150px;
          }
          .searchSelectType .selected-option {
            box-shadow: none;
          }
          .searchSelectType .selected-option input {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 150px;
            &::placeholder {
              color: black;
            }
          }
        }
        .typeSelectFilter {
          flex: 0.5;
        }
        .typeSelectDateSec {
          flex: 2;
          display: flex;
          gap: 20px;
          .mainLabelFilter {
            display: flex;
            align-items: flex-end;
            .arrowBtnSec {
              display: flex;
              align-items: center;
              gap: 15px;
              margin-bottom: 0px;
              p {
                margin: 0;
                font-family: Montserrat;
                font-size: 16px;
                font-weight: 600;
                color: #4d4d4d;
              }
              .parentFilterBtn {
                flex: 1;
              }
              .scoreBtn {
                padding: 8px 25px !important;
                width: fit-content;
                margin: 0;
                background-color: #fff;
                border: 1px solid #b3b3b3;
                border-radius: 5px;
                margin-bottom: 0;
              }
              .borderGreen {
                border-color: var(--primary-green);
              }
              .borderGrey {
                border-color: #b3b3b3;
              }
            }
          }
        }
      }
      .responseSecFilter {
        gap: 20px;
        margin-top: 30px;
      }
      .mainLabelFilter {
        @include fontStyle;
        font-size: 16px;
        font-weight: 500;
      }
      .radioCheckLabel {
        @include fontStyle;
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        position: relative;
        top: 3px;
        cursor: pointer;
      }
      .manageClearAll {
        display: flex;
        justify-content: space-between;
      }
    }
    .addMargin {
      margin-bottom: 40px;
    }
  }
  .totalContentDiv {
    .totalContent {
      display: flex;
      justify-content: end;
      font-size: 12px;
      font-weight: 600;
      color: var(--darker-grey);
      margin-right: 10px;
    }
  }
  .AssessmentListContentSec {
    padding: 0;
    .AssessmentListContentTitle {
      @include fontStyle;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .AssessmentListCard {
      display: flex;
      border: 1px solid #efefef;
      margin-bottom: 20px;
      &:hover {
        box-shadow: 0 0 10px #35d4ae;
      }
      .AssessmentListCardImg {
        background-color: #edfffb;
        padding: 15px 30px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        .profileImage {
          // width: 250px;
          // height: 250px;
          border-radius: 50%;
          object-fit: contain;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 0px 6px 5px #0000001A;
          .logo-img {
            border-radius: 50%;
            width: 100px;
            height: 100px;
          }
        }
        h6 {
          @include fontStyle;
          font-size: 14px;
          font-weight: 500;
        }
        h4 {
          @include fontStyle;
          font-size: 20px;
          font-weight: 700;
          width: max-content;
          text-transform: capitalize;
        }
      }
      .AssessmentListCardDetails {
        width: 100%;
        padding: 0px 31px;
        flex: 6;
        .ListCardDetailsTitleSec {
          @include flexCss;
          gap: 79px;
          .ListCardDetailsTitle {
            width: 100%;
            .bulletSec {
              margin-bottom: 15px;
            }
            .listItemSec {
              border-bottom: 1px solid #c7c7c7;
              margin: 20px 0;
              .ListCardTitleHeading {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                h3 {
                  @include fontStyle;
                  font-size: 22px;
                  font-weight: 600;
                  // ellipsis code
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  word-break: break-word;
                }
                .listCopyIcon {
                  position: relative;
                  top: -6px;
                  left: 0px;
                  margin: 0 10px;
                }
                .d-flex {
                  align-items: center;
                  .assessmentCommonBtn {
                    margin: 6px;
                    button {
                      padding: 4px 6px;
                    }
                  }
                }
                .shadowSvg {
                  box-shadow: 0px 0px 20px 0px #0000001a;
                  border-radius: 50%;
                  margin: 0 10px;
                }
                .center-gap {
                  display: flex;
                  align-items: center;
                  background-color: #35d4ae;
                  justify-content: center;
                }
                .link_wrap {
                  margin: 8px 10px 8px 0;
                  margin-right: 10px;
                  position: relative;
                  .acc_style04 {
                    border-radius: 3px;
                    font-size: 14px;
                    font-weight: 500;
                    background: #e1f9f3;
                    padding: 6px 8px 6px 24px;
                    color: var(--primary-black);
                    transition: all 0.2s ease-in;
                    position: relative;
                    width: fit-content;
                    margin-bottom: 0;
                    &::before {
                      content: "";
                      position: absolute;
                      background: var(--primary-green);
                      clip-path: polygon(100% 0%,
                          100% 100%,
                          50% 75%,
                          0 100%,
                          0 0);
                      transition: all 0.2s ease-in;
                      left: 6px;
                      top: -2px;
                      width: 12px;
                      height: 60%;
                    }
                  }
                }
              }
              .email-mobile-section {
                @include fontStyle;
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 8px;
                a {
                  @include fontStyle;
                  font-size: 14px;
                  font-weight: 500;
                  margin-bottom: 8px;
                  text-decoration: none;
                }
              }
            }
          }
        }
        .ListCardDetailsContent {
          display: flex;
          align-items: center;
          gap: 100px;
          p {
            margin-bottom: 5px;
          }
        }
        .ListContentSkillsSec {
          @include flexCss;
          align-items: center;
          gap: 79px;
          margin-bottom: 10px;
          .ListContentSkills {
            display: flex;
            gap: 100px;
            width: 100%;
            p {
              margin-bottom: 5px;
              font-weight: 500;
            }
          }
          .decreaseGap {
            gap: 78px;
            h5 {
              font-weight: 600;
              margin-bottom: 12px;
            }
          }
          .RightSideArrowSec {
            margin-top: 35px;
          }
        }
      }
    }
    .totalContent {
      display: flex;
      justify-content: end;
      font-size: 12px;
      font-weight: 600;
      color: #5a5a5a;
    }
  }
}
.companyListWrapper {
  .CreatedAssessmentListTitle {
    margin: 45px 0;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    h2 {
      @include fontStyle;
      font-size: 26px;
      font-weight: 600;
      margin-bottom: 0;
    }
    .companyNameTitle {
      // align-items: center;
      gap: 5px;
      margin-bottom: 30px;
      display: flex;
      color: #4d4d4d;
      font-family: Montserrat;
      line-height: normal;
      font-style: normal;
      font-size: 22px;
      font-weight: 600;
      .companyNameHeading {
        color: #4d4d4d;
        font-family: Montserrat;
        line-height: normal;
        font-style: normal;
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 0;
      }
    }
  }
  min-height: calc(100vh - 180px);
  .AssessmentListCardDetails {
    width: 100%;
    padding: 0px 31px;
    flex: 6;
    .listItemSec {
      border-bottom: 1px solid #c7c7c7;
      margin: 20px 0;
      .ListCardTitleHeading {
        justify-content: space-between;
        .listinIconDiv {
          cursor: pointer;
          svg {
            width: 70px;
            height: 70px;
          }
        }
        p {
          color: #4d4d4d;
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 8px;
        }
        h3 {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          color: #4d4d4d;
          display: -webkit-box;
          font-family: Montserrat;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }
      }
      .bulletSec {
        margin-bottom: 15px;
      }
      .afterh2 {
        position: relative;
        z-index: 2;
        font-size: 14px;
        font-weight: 500;
        color: #5a5a5a;
        span {
          background-color: #fff;
          position: relative;
          z-index: 2;
          padding-right: 15px;
        }
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 0.5em;
          border-top: 1px solid #c7c7c7;
          z-index: 1;
          margin-right: 1.5rem;
        }
      }
      .listDetailsSec {
        // margin: 10px 0;
        label {
          font-size: 16px;
          font-weight: 500;
          color: #5a5a5a;
        }
        h5 {
          // font-size: 16px;
          font-weight: 600;
          color: #4d4d4d;
          margin-top: 5px;
        }
      }
    }
    .ListContentSkillsSec {
      @include flexCss;
      align-items: center;
      gap: 79px;
      margin-bottom: 10px;
      .ListContentSkills {
        display: flex;
        gap: 100px;
        width: 100%;
        p {
          margin-bottom: 5px;
          font-weight: 500;
          color: #5a5a5a;
        }
        .clickable-fields {
          cursor: pointer;
        }
      }
      .decreaseGap {
        gap: 78px;
        h5 {
          font-weight: 600;
        }
      }
      .RightSideArrowSec {
        margin-top: 35px;
      }
    }
  }
}
@media(max-width:1020px) {
  .createdAssessmentList {
    .AssessmentListContentSec {
      .AssessmentListCard {
        flex-direction: column;
        .AssessmentListCardDetails {
          .ListContentSkillsSec {
            flex-direction: column;
            gap: 0px;
            .RightSideArrowSec {
              align-self: flex-end;
            }
          }
        }
      }
    }
  }
}
@media(max-width:768px) {
  .createdAssessmentList {
    .AssessmentListContentSec {
      .AssessmentListCard {
        .AssessmentListCardDetails {
          .ListContentSkillsSec {
            .decreaseGap {
              gap: 0;
              h5 {
                margin-bottom: 10px;
              }
            }
            .ListContentSkills {
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}
@media(max-width:540px) {
  .createdAssessmentList .AssessmentListContentSec .AssessmentListCard .AssessmentListCardDetails .ListCardDetailsTitleSec .ListCardDetailsTitle .listItemSec .email-mobile-section {
    display: flex;
    flex-direction: column;
    a {
      margin-bottom: 0;
      word-break: break-all;
    }
  }
}
@media(max-width:992px) {
  .createdAssessmentList .filterClicked .typeSelectSec {
    flex-direction: column;
  }
  .createdAssessmentList .filterClicked .typeSelectSec .typeSelectDateSec {
    flex-wrap: wrap;
  }
}
@media(max-width:430px) {
  .createdAssessmentList .assessmentListSearchSec {
    flex-direction: column;
    align-items: flex-end;
  }
}