@mixin flexCss {
  display: flex;
  justify-content: space-between;
}
@mixin fontStyle {
  color: #4d4d4d;
  font-family: Montserrat;
  line-height: normal;
  font-style: normal;
}
.AssessmentsListWrapper {
  min-height: calc(100vh - 180px);
  .backArrow {
    cursor: pointer;
  }
  .companyNameTitle {
    align-items: baseline;
    gap: 5px;
    margin-bottom: 30px;
    display: flex;
    color: #4d4d4d;
    font-family: Montserrat;
    line-height: normal;
    font-style: normal;
    font-size: 22px;
    font-weight: 600;
    .companyNameHeading {
      flex: 1;
      display: flex;
      justify-content: space-between;
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 600;
      color: #5A5A5A;
    }
  }
  .assessmentListSearchSec {
    display: flex;
    align-items: center;
    gap: 20px;
    position: relative;
    z-index: 1;
    .templateSearchField {
      width: 100%;
    }
    .templateSearchField .templateSearchIconSec input.form-control {
      box-shadow: 0px 5px 25px 0px #c0c0c033;
      border: 2px solid #e9e9e9;
    }
  }
  .totalAssess {
    color: #5a5a5a;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    justify-content: end;
    margin-bottom: 10px
  }
  .AssessmentListContentSec {
    .AssessmentListContentTitle {
      @include fontStyle;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .AssessmentListCard {
      display: flex;
      border: 1px solid #efefef;
      margin-bottom: 20px;
      &:hover {
        box-shadow: 0 0 10px #35d4ae;
      }
      .AssessmentListCardImg {
        background-color: #edfffb;
        padding: 15px 30px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        h6 {
          @include fontStyle;
          font-size: 14px;
          font-weight: 500;
        }
        h4 {
          @include fontStyle;
          font-size: 20px;
          font-weight: 700;
          width: max-content;
        }
      }
      .AssessmentListCardDetails {
        width: 100%;
        padding: 0px 31px;
        flex: 6;
        .ListCardDetailsTitleSec {
          @include flexCss;
          gap: 79px;
          .ListCardDetailsTitle {
            width: 100%;
            .bulletSec {
              margin-bottom: 15px;
            }
            .listItemSec {
              border-bottom: 1px solid #c7c7c7;
              margin: 20px 0;
              .ListCardTitleHeading {
                display: flex;
                justify-content: space-between;
                h3 {
                  @include fontStyle;
                  font-size: 22px;
                  font-weight: 600;
                  // ellipsis code
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  word-break: break-word;
                }
                .listCopyIcon {
                  position: relative;
                  top: -6px;
                  left: 0px;
                  margin: 0 10px;
                }
                .d-flex {
                  align-items: center;
                  .assessmentCommonBtn {
                    margin: 6px;
                    button {
                      padding: 4px 6px;
                    }
                  }
                }
                .shadowSvg {
                  box-shadow: 0px 0px 20px 0px #0000001a;
                  border-radius: 50%;
                  margin: 0 10px;
                }
                p {
                  @include fontStyle;
                  font-size: 14px;
                  font-weight: 500;
                  margin-bottom: 8px;
                }
                .center-gap {
                  display: flex;
                  align-items: center;
                  background-color: #35d4ae;
                  justify-content: center;
                }
              }
            }
          }
        }
        .ListCardDetailsContent {
          display: flex;
          align-items: center;
          gap: 100px;
          p {
            margin-bottom: 5px;
          }
        }
        .ListContentSkillsSec {
          @include flexCss;
          align-items: center;
          gap: 79px;
          margin-bottom: 10px;
          .ListContentSkills {
            display: flex;
            gap: 100px;
            width: 100%;
            p {
              margin-bottom: 5px;
              font-weight: 500;
            }
          }
          .decreaseGap {
            gap: 78px;
            h5 {
              font-weight: 600;
            }
          }
          .RightSideArrowSec {
            margin-top: 35px;
          }
        }
      }
    }
    .totalContent {
      display: flex;
      justify-content: end;
      font-size: 12px;
      font-weight: 600;
      color: #5a5a5a;
    }
  }
}
.link_wrap {
  margin: 8px 0;
  margin-right: 10px;
  position: relative;
  .acc_style04 {
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    background: #e1f9f3;
    padding: 6px 8px 6px 24px;
    color: var(--primary-black);
    transition: all 0.2s ease-in;
    position: relative;
    width: fit-content;
    margin-bottom: 0;
    &::before {
      content: "";
      position: absolute;
      background: var(--primary-green);
      clip-path: polygon(100% 0%,
          100% 100%,
          50% 75%,
          0 100%,
          0 0);
      transition: all 0.2s ease-in;
      left: 6px;
      top: -2px;
      width: 12px;
      height: 60%;
    }
  }
}
@media (max-width: 768px) {
  .AssessmentsListWrapper .AssessmentListContentSec .AssessmentListCard .AssessmentListCardDetails .ListContentSkillsSec .ListContentSkills {
    gap: 0px;
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .AssessmentsListWrapper .AssessmentListContentSec .AssessmentListCard {
    flex-direction: column;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .AssessmentsListWrapper .AssessmentListContentSec .AssessmentListCard {
    flex-direction: column;
  }
}
@media(max-width:992px) {
  .AssessmentsListWrapper .companyNameTitle .companyNameHeading {
    flex-direction: column;
    gap: 10px;
  }
}
@media(max-width:901px) {
  .AssessmentsListWrapper .AssessmentListContentSec .AssessmentListCard .AssessmentListCardDetails .ListContentSkillsSec {
    .decreaseGap {
      gap: 20px;
    }
    .ListContentSkills p {
      font-size: 14px;
    }
  }
}