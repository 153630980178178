:root {
  --primary-white: #ffffff;
  --primary-black: #000000;
  --primary-green: #35d4ae;
  --secondary-green: #dffff7;
  --leaf-grean: #00bfa6;
  --dark-green: #35d4ae33;
  --light-green: #abffeb;
  --moderate-green: #d6fff1;
  --very-light-green: #f6ffff;
  --light-grey: #b3b3b3;
  --light-black: #4d4d4d;
  --darker-grey: #5a5a5a;
  --very-light-grey: #f1f1f1;
  --dark-white: #f9f9f9;
  --off-white: #efefef;
  --light-pink: #ffb0b0;
  --disable-dark-grey: #fff3;
  --disable-light-grey: #d9d9d9;
  --disable-strip-grey: #c6c6c6;
  --loader-grey: #8f8f8f;
  --division-grey: #c7c7c7;
  --bg-white: #fafafa;
  --thumb-white: #e3e3e3;
  --border-shadow: #c0c0c033;
  --url-input-grey: #e9e9e9;
  --icons-grey: #9e9e9e;
  --fail-pink: #ffc9c9;
  --modal-btn-disable-grey: #a1a1a1;
  --bug-light-red: #ff995533;
  --red: red;
  --secondary-red: #e41b1b;
  --orange: #fe8208;
  --primary-blue: #2ebec6;
  --border-grey: #ccc;
  --light-white: #f0f0f0;
  --warning-yellow: rgb(254, 239, 195);
  --active-green: #edfffb;
  --light-red: #da5d5d;
  --pacific-cyan: #00aad5;
  --safety-orange: #ff8635;
  --primary-golden: #dbb52f;
  --light-bg-green: #ebfff8;
  --blue: #0000ff;
}
/* Dark theme variables */
[data-theme="dark"] {
  --primary-white: #121212;
  --primary-black: #ffffff;
  --secondary-green: #333333;
  --primary-green: #555555;
  --light-grey: #666666;
  --light-black: #ffffff;
  --darker-grey: #ffffff;
  --very-light-green: #666666;
  --dark-white: #b3b3b3;
}
body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body[dir="rtl"] {
  direction: rtl;
  text-align: right;
}
body[dir="ltr"] {
  direction: ltr;
  text-align: left;
}
body .container-fluid {
  padding: 0 40px;
}
.cursorPointer {
  cursor: pointer;
}
.displayNone {
  display: none;
}
.wordBreak {
  word-break: break-word;
}
.lightBtn {
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 8px 20px !important;
  color: var(--primary-black) !important;
  background-color: var(--secondary-green) !important;
  border: 1px solid var(--primary-green) !important;
  border-radius: 5px !important;
  &:disabled {
    border: none !important;
    background-color: var(--light-grey) !important;
    border: 1px solid var(--light-grey) !important;
    color: var(--primary-white) !important;
  }
}
.dateSize {
  font-size: 18px;
}
.divider {
  flex-grow: 1;
  margin-left: 1rem;
  border: none;
  border-top: 1.5px solid var(--light-black);
}
.seperationPipe {
  font-size: 14px;
}
.assessmentListSearchSec {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  z-index: 1;
  .templateSearchField {
    width: 100%;
  }
  .templateSearchField .templateSearchIconSec input.form-control {
    box-shadow: 0px 5px 25px 0px #c0c0c033;
    border: 2px solid #e9e9e9;
  }
}
.visibilityHide {
  visibility: hidden
}
.minWidth {
  min-height: calc(100vh - 220px);
}
/* For Chrome, Safari and other WebKit-based browsers */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background: var(--very-light-grey);
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}